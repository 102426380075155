import axios from 'axios';

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? 'https://javadev-ugnplleymq-uw.a.run.app'
      : 'https://javadev-ugnplleymq-uw.a.run.app',
});

instance.defaults.withCredentials = true;
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default instance;
